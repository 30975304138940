import React, { useState } from 'react';
import govInvestLogoHorizontal from '../../assets/GI_Logo_Horizontal.png';
import trueCompLogoVertical from '../../assets/TC_Logo_vert.png';
import styled from 'styled-components';
import AuthManager from '../../shared/authManager';
import Button from '../../shared/components/Button';
import { fontSizes } from '../../styles/vars';
import { validateEmail } from '../../lib/validators';
import Spinner from 'shared/components/spinner';

const LoginContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 12%;
  line-height: 1.4;
`;

const LoginGrid = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;
  margin-bottom: 20vh;
  padding: 15px;
`;

const LogoGrid = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 20px;
  align-items: center;
  justify-content: center;
  width: 600px;
`;

const LogoTitleContainer = styled.div`
  display: grid;
  grid-row-gap: 10px;
  align-items: center;
  justify-items: center;
`;

const StyledImg = styled.img`
  width: 100%;
  height: 100%;
`;

const StyledLogoIcon = styled(StyledImg)`
  width: 150px;
`;

const GovInvestTitleText = styled.p`
  font-size: 0.875rem;
  color: var(--color-darkgrey);
  text-align: center;
`;

const TrueCompTitleText = styled.p`
  font-size: 0.75rem;
  color: var(--color-grey-d);
  text-align: center;
`;

function Login() {
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [password, setPassword] = useState('');
  const [dbConnection, setDbConnection] = useState(true);
  const [showSpinner, setShowSpinner] = useState(false);
  const auth = new AuthManager();

  const handleEmailChanged = (e) => {
    const newEmail = e.target.value;
    setEmail(e.target.value);
    const isValidEmail = validateEmail(newEmail);
    setIsValidEmail(isValidEmail);
    if(!isValidEmail) {
      setDbConnection(true);
      return;
    }

    const envConfig = window.env;
    const config = {
      clientId: envConfig.clientId,
      iumBaseUrl: envConfig.baseUrl,
    }

    const encodedEmail = encodeURI(e.target.value);
    const clientId = config.clientId;
    fetch(`/api/userconnections?email=${encodedEmail}&clientId=${clientId}`, { method: 'GET', })
      .then(r => {
        if(r.ok) {
          setDbConnection(false);
        }
      });
  }

  const handlePasswordChanged = (e) => {
    setPassword(e.target.value);
  };

  function handleLoginClick() {
    if(!email) {
      alert('Please provide an e-mail address.')
      return;
    }
    setShowSpinner(true);
    if(dbConnection) {
      auth.doDeviceDatabaseLogin(email, password);
    } else {
      auth.doDeviceSsoLogin(email);
    }
  }

  const handlePasswordOnKeyUp = (e) => {
    if(e.key === 'Enter') {
      setShowSpinner(true);
      handleLoginClick();
    }
  };

  const handleEmailOnKeyUp = (e) => {
    if(e.key === 'Enter' && !dbConnection) {
      setShowSpinner(true);
      auth.doDeviceSsoLogin(email);
    }
  };


  var showPassword = true;

  if(!dbConnection) {
    showPassword = false;
  }

  var enableLoginButton = true;
  if(!isValidEmail) {
    enableLoginButton = false;
  }
  if(dbConnection && (!password || password.length === 0)) {
    enableLoginButton = false;
  }

  if(showSpinner)
    return <Spinner />

  return (

    <LoginContainer>
      <LoginGrid>
        <LogoGrid>
          <StyledLogoIcon
            src={govInvestLogoHorizontal}
            alt={'govinvest logo icon'}
            width='108px'
            height='27px'
          />
        </LogoGrid>
        <LogoTitleContainer>
          <GovInvestTitleText>is evolving...<strong>Welcome to TrueComp</strong></GovInvestTitleText>
        </LogoTitleContainer>
        <LogoGrid>
          <StyledLogoIcon
            src={trueCompLogoVertical}
            alt={'true comp logo icon'}
            width='230px'
            height='119px'
          />
        </LogoGrid>
        <LogoTitleContainer>
          <TrueCompTitleText>
            Over the coming months we will be integrating our
            <br />
            new identity into our exceptional products.
          </TrueCompTitleText>
        </LogoTitleContainer>

        <div style={{width: '300px', display:'flex', flexDirection:'column', alignItems:'left'}}>

          <div style={{fontSize:fontSizes.s, padding:'2px'}}>Email</div>
          <input style={{width:'300px', fontSize: fontSizes.m}} type="email" onChange={handleEmailChanged} onKeyUp={handleEmailOnKeyUp} placeholder="email" value={email}></input>

          { showPassword && (
            <>
              <div style={{fontSize:fontSizes.s, padding:'2px', marginTop: '5px'}}>Password</div>
              <input style={{width:'300px', fontSize: fontSizes.m}} type="password" onChange={handlePasswordChanged} onKeyUp={handlePasswordOnKeyUp} placeholder="password" value={password}></input>
            </>
          )}
          <div style={{ display: 'flex', flexDirection: 'row-reverse'}}>
            <Button disabled={!enableLoginButton} truecompprimary onClick={handleLoginClick}>
              Login
            </Button>
          </div>
        </div>
      </LoginGrid>
    </LoginContainer>
  );
};

export default Login;