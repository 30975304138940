import React, { useState } from 'react';
import LockIcon from '@material-ui/icons/Lock';
import styled, { css, keyframes } from 'styled-components';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import ContactMailOutlinedIcon from '@material-ui/icons/ContactMailOutlined';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import { colors } from '../../styles/colors';
import { fontSizes } from '../../styles/vars';
import Spinner from 'shared/components/spinner';
import AuthManager from '../../shared/authManager';
import { validateEmail } from '../../lib/validators';
import { postPublicCommand } from '../../shared/commandActions';
import govInvestLogoHorizontal from '../../assets/GI_Logo_Horizontal.png';
import trueCompLogoVertical from '../../assets/TC_Logo_vert.png';
import CustomInput from '../../shared/components/CustomInput';

function Login() {
  const [email, setEmail] = useState('');
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [password, setPassword] = useState('');
  const [suppliedEmailIsInvalid, setSuppliedEmailIsInvalid] = useState(false);
  const [suppliedPasswordIsInvalid, setSuppliedPasswordIsInvalid] =
    useState(false);
  const [dbConnection, setDbConnection] = useState(true);
  const [forgotPasswordVisible, setForgotPasswordVisible] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [codeSent, setCodeSent] = useState(false);
  const [passwordReset, setPasswordReset] = useState(false);
  const [statusBanner, setStatusBanner] = useState({
    visible: false,
    message: '',
    type: '',
  });

  const [resetEmail, setResetEmail] = useState('');
  const [suppliedResetEmailIsInvalid, setSuppliedResetEmailIsInvalid] =
    useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [code, setCode] = useState('');
  const [from, setFrom] = useState('dbLogin');
  const [to, setTo] = useState('codeRequest');

  const auth = new AuthManager();

  const handleGetCodeClicked = async () => {
    validateInputs();
    const emailIsValid = validateEmail(resetEmail);
    if (emailIsValid) {
      const cmd = {
        type: 'REQUEST_PASSWORD_RESET_CODE',
        payload: {
          email: resetEmail,
        },
      };
      const result = await postPublicCommand(cmd);
      if (result.error) {
        displayStatusMessage(true, result.errorDescription, 'error');
      } else {
        setCodeSent(true);
        setFrom('codeRequest');
        setTo('codeSent');
      }
    } else {
      setSuppliedResetEmailIsInvalid(true);
    }
  };

  const isLongEnough = pw => {
    return pw.length >= 8;
  };

  const handleResetClicked = async () => {
    const emailIsValid = validateEmail(resetEmail);
    const validCode = code.length === 6;
    const resetPasswordEnabled =
      emailIsValid &&
      validCode &&
      isLongEnough(newPassword) &&
      newPassword === confirmNewPassword;
    if (resetPasswordEnabled) {
      const cmd = {
        type: 'CHANGE_PASSWORD_WITH_CODE',
        payload: {
          email: resetEmail,
          code: code,
          newPassword: newPassword,
        },
      };
      const result = await postPublicCommand(cmd);
      if (result.error) {
        displayStatusMessage(
          true,
          'Please check your reset code and make sure you are using a strong password',
          'error'
        );
      } else {
        setPasswordReset(true);
        setCode('');
        setPassword('');
        setNewPassword('');
        setConfirmNewPassword('');
        displayStatusMessage(
          true,
          'Your password was successfully reset',
          'success'
        );
      }
    }
  };

  const checkLoginEnabled = () => {
    let loginEnabled = true;
    if (emailIsValid && !dbConnection) {
      return loginEnabled;
    }
    if (!emailIsValid) {
      loginEnabled = false;
    }
    if (dbConnection && (!password || password.length === 0)) {
      loginEnabled = false;
    }
    return loginEnabled;
  };

  const validateInputs = () => {
    if (!emailIsValid) {
      setSuppliedEmailIsInvalid(true);
    }
    if (!password) {
      setSuppliedPasswordIsInvalid(true);
    }
  };

  const handleEmailChanged = e => {
    const newEmail = e.target.value;
    setEmail(e.target.value);
    setSuppliedEmailIsInvalid(false);
    const validatedEmail = validateEmail(newEmail);
    setEmailIsValid(validatedEmail);
    if (!validatedEmail) {
      setDbConnection(true);
      setFrom('enterpriseLogin');
      return;
    }

    const envConfig = window.env;

    const encodedEmail = encodeURI(e.target.value);
    const clientId = envConfig.clientId;
    fetch(`/api/userconnections?email=${encodedEmail}&clientId=${clientId}`, {
      method: 'GET',
    }).then(r => {
      if (r.ok) {
        setDbConnection(false);
        setFrom('dbLogin');
      }
    });
  };

  const handlePasswordChanged = e => {
    setSuppliedPasswordIsInvalid(false);
    setPassword(e.target.value);
  };

  function handleLoginClick() {
    const loginEnabled = checkLoginEnabled();
    validateInputs();
    if (loginEnabled) {
      setShowSpinner(true);
      if (dbConnection) {
        auth.doUserDatabaseLogin(email, password);
      } else {
        auth.doSsoLogin(email);
      }
    }
  }

  const handleOnKeyUp = e => {
    if (e.key === 'Enter') {
      handleLoginClick();
    }
  };

  const handleResetEmailChanged = e => {
    setSuppliedResetEmailIsInvalid(false);
    setResetEmail(e.target.value);
  };

  const handleCodeChanged = e => {
    setCode(e.target.value);
  };

  const handleNewPasswordChanged = e => {
    setNewPassword(e.target.value);
  };

  const handleConfirmNewPasswordChanged = e => {
    setConfirmNewPassword(e.target.value);
  };

  const handleInputsOnLoginNavigation = () => {
    if (!forgotPasswordVisible) {
      setResetEmail(email);
    }
    setCode('');
    setPassword('');
    setNewPassword('');
    setConfirmNewPassword('');
  };

  const displayStatusMessage = (bool, message, type) => {
    if (bool) {
      setStatusBanner({
        visible: bool,
        message: message,
        type: type,
      });
    } else {
      setStatusBanner({
        visible: bool,
        message: '',
        type: '',
      });
    }
  };

  const handleReturnToLogin = () => {
    displayStatusMessage(false);
    handleSetForgotPasswordVisible(false);
    setTimeout(() => {
      setCodeSent(false);
      setPasswordReset(false);
    }, 300);
  };

  const handleLoginNavigation = () => {
    if (forgotPasswordVisible) {
      if (codeSent) {
        setFrom('codeSent');
      }
      if (!codeSent) {
        setFrom('codeRequest');
      }
      if (dbConnection) {
        setTo('dbLogin');
      }
      if (!dbConnection) {
        setTo('enterpriseLogin');
      }
      return;
    }

    if (!forgotPasswordVisible) {
      if (dbConnection) {
        setFrom('dbLogin');
        setTo('codeRequest');
        return;
      }
      if (!dbConnection) {
        setFrom('enterpriseLogin');
        setTo('codeRequest');
        return;
      }
    }
  };

  const handleSetForgotPasswordVisible = bool => {
    setSuppliedEmailIsInvalid(false);
    setSuppliedPasswordIsInvalid(false);
    setForgotPasswordVisible(bool);
    handleInputsOnLoginNavigation();
    handleLoginNavigation();
  };

  const validCode = code.length === 6;
  const newPasswordValid = isLongEnough(newPassword);
  const confirmNewPasswordValid = newPassword === confirmNewPassword;
  const emailInputLabel = suppliedEmailIsInvalid ? 'Invalid email' : 'Email';
  const resetEmailInputLabel = suppliedResetEmailIsInvalid
    ? `Invalid email`
    : 'Email';
  const passwordInputLabel = suppliedPasswordIsInvalid
    ? `Password required`
    : 'Password';

  var showPassword = true;

  if (!dbConnection) {
    showPassword = false;
  }

  if (showSpinner) return <Spinner />;

  return (
    <LoginScreen>
      <BackgroundTransition />
      <LoginCard>
        <LoginBody>
          <LogoGrid>
            <StyledLogoIcon
              src={govInvestLogoHorizontal}
              alt={'govinvest logo icon'}
              width='108px'
              height='27px'
            />
          </LogoGrid>
          <LogoTitleContainer>
            <GovInvestTitleText>is evolving...<strong>Welcome to TrueComp</strong></GovInvestTitleText>
          </LogoTitleContainer>
          <LogoGrid>
            <StyledLogoIcon
                src={trueCompLogoVertical}
                alt={'true comp logo icon'}
                width='230px'
                height='119px'
            />
          </LogoGrid>
          <LogoTitleContainer>
            <TrueCompTitleText>
              Over the coming months we will be integrating our
              <br />
              new identity into our exceptional products.
            </TrueCompTitleText>
          </LogoTitleContainer>
          <TitleText>{forgotPasswordVisible && 'Reset Password'}</TitleText>
          {statusBanner.visible && (
            <StatusBanner error={statusBanner.type === 'error'}>
              {statusBanner.message}
            </StatusBanner>
          )}
          <ContentContainer shrink={!dbConnection}>
            <ContentFiller
              to={to}
              from={from}
              resetPasswordMode={forgotPasswordVisible}
              codeSent={codeSent}
              enterpriseMode={!dbConnection}
            />
            <CredentialsContainer
              from={from}
              to={to}
              resetPasswordMode={forgotPasswordVisible}
            >
              <CredentialsInputContainer>
                <CustomInput
                  type='email'
                  value={email}
                  label={emailInputLabel}
                  onChange={handleEmailChanged}
                  onKeyUp={handleOnKeyUp}
                  error={suppliedEmailIsInvalid}
                  Icon={<ContactMailOutlinedIcon />}
                />
                {showPassword && (
                  <CustomInput
                    type='password'
                    value={password}
                    label={passwordInputLabel}
                    onChange={handlePasswordChanged}
                    onKeyUp={handleOnKeyUp}
                    error={suppliedPasswordIsInvalid}
                    Icon={<LockOutlinedIcon />}
                  />
                )}
              </CredentialsInputContainer>
            </CredentialsContainer>

            <PasswordResetContainer
              resetPasswordMode={forgotPasswordVisible}
              enterpriseMode={!dbConnection}
            >
              {!codeSent && (
                <>
                  <div
                    style={{
                      padding: '10px 0',
                      fontSize: fontSizes.s,
                      color: colors.grey,
                      textAlign: 'center',
                      marginBottom: '5px',
                    }}
                  >
                    Please enter your email address. A password reset code will
                    be emailed to you.
                  </div>
                  <div style={{ display: 'flex', fontSize: fontSizes.r }}>
                    <CustomInput
                      type='email'
                      value={resetEmail}
                      label={resetEmailInputLabel}
                      onChange={handleResetEmailChanged}
                      onKeyUp={handleOnKeyUp}
                      error={suppliedResetEmailIsInvalid}
                      Icon={<ContactMailOutlinedIcon />}
                    />
                  </div>
                </>
              )}
              {codeSent && (
                <>
                  <div
                    style={{
                      fontSize: fontSizes.s,
                      fontStyle: 'italic',
                      textAlign: 'center',
                      color: colors.grey,
                      marginBottom: '10px',
                    }}
                  >
                    Please check your email for your password reset code. This
                    code will be valid for 15 minutes.
                    <br />
                    <br />
                    We strongly recommend using a long (20+) randomly generated
                    password and storing it in a password manager.
                  </div>

                  <CredentialsInputContainer>
                    <CustomInput
                      type='text'
                      value={code}
                      label={'Code'}
                      onChange={handleCodeChanged}
                      // onKeyUp={handleOnKeyUp}
                      error={!validCode && !passwordReset}
                      disabled={passwordReset}
                      Icon={<VerifiedUserOutlinedIcon />}
                    />
                    <CustomInput
                      type='password'
                      value={newPassword}
                      label={'New Password'}
                      onChange={handleNewPasswordChanged}
                      // onKeyUp={handleOnKeyUp}
                      error={!newPasswordValid && !passwordReset}
                      disabled={passwordReset}
                      Icon={<LockOutlinedIcon />}
                    />
                    <CustomInput
                      type='password'
                      value={confirmNewPassword}
                      label={'Confirm New Password'}
                      onChange={handleConfirmNewPasswordChanged}
                      // onKeyUp={handleOnKeyUp}
                      error={!confirmNewPasswordValid && !passwordReset}
                      disabled={passwordReset}
                      Icon={<LockIcon />}
                    />
                  </CredentialsInputContainer>
                </>
              )}
            </PasswordResetContainer>

          </ContentContainer>
          {!forgotPasswordVisible && (
            <LoginButton onClick={handleLoginClick}>Login</LoginButton>
          )}
          {forgotPasswordVisible && !codeSent && (
            <LoginButton onClick={handleGetCodeClicked}>Send Code</LoginButton>
          )}
          {forgotPasswordVisible && codeSent && !passwordReset && (
            <LoginButton onClick={handleResetClicked}>
              Reset Password
            </LoginButton>
          )}
          {forgotPasswordVisible && passwordReset && (
            <LoginButton onClick={handleReturnToLogin}>
              Return to Login
            </LoginButton>
          )}
        </LoginBody>
        <LoginFooter>
          {forgotPasswordVisible ? (
            <FooterButton onClick={handleReturnToLogin}>
              Return to login
            </FooterButton>
          ) : (
            <FooterButton onClick={() => handleSetForgotPasswordVisible(true)}>
              Forgot password?
            </FooterButton>
          )}
        </LoginFooter>
      </LoginCard>
    </LoginScreen>
  );
}

// Style Getters START
const getFillerHeight = props => {
  if (!props.resetPasswordMode) {
    if (!props.enterpriseMode) {
      return '120px';
    }
    if (props.enterpriseMode) {
      return '72.5px';
    }
  }
  if (props.resetPasswordMode) {
    if (!props.codeSent) {
      return '130px';
    }
    if (props.codeSent) {
      return '300px';
    }
  }
};

const getChangeFillerHeightDelay = props => {
  const { to, from } = props;

  if (from === 'dbLogin') {
    return '0s';
  }
  if (from === 'enterpriseLogin') {
    if (to === 'codeRequest') {
      return '0.3s';
    } else {
      return '0s';
    }
  }
  if (from === 'codeRequest') {
    if (to === 'codeSent') {
      return '0s';
    } else {
      return '0.3s';
    }
  }
  if (from === 'codeSent') {
    return '0.3s';
  }

  return '0.3s';
};

const getMovePositionDelay = props => {
  const { to, from } = props;
  if (from === 'dbLogin') {
    return '0s';
  }
  if (from === 'enterpriseLogin') {
    return '0s';
  }
  if (from === 'codeRequest') {
    if (to === 'enterpriseLogin') {
      return '0.45s';
    } else {
      return '0.3s';
    }
  }
  if (from === 'codeSent') {
    return '0.6s';
  }
};
// Style getters END

// Style animations START
const flyIn = keyframes`
  from {
    transform: translateY(30%) scale(0.7);
    opacity: 0;
  }
  to {
    transform: translateY(0%) scale(1);
    opacity: 1;
  }
`;

const backgroundEnter = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

const appear = keyframes`
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
`;

const disappear = keyframes`
  99% {
    visibility: visible;
  }
  100% {
    visibility: hidden;
  }
`;

const moveOutMoveIn = props =>
  css`
    0.5s ${props => (props.resetPasswordMode ? disappear : appear)};
  `;

const moveInMoveOut = props =>
  css`
    0.5s ${props => (props.resetPasswordMode ? appear : disappear)};
  `;
// Style animations END

// Component styles START
const ContentFiller = styled.div`
  height: ${props => getFillerHeight(props)};
  transition: height 0.3s ease-in-out;
  transition-delay: ${props => getChangeFillerHeightDelay(props)};
  z-index: -1;
`;

const LoginScreen = styled.div`
  display: grid;
  align-content: center;
  justify-content: center;
  background: radial-gradient(#243580, #131c44);
  height: 100vh;
`;

const BackgroundTransition = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: white;
  opacity: 0;
  animation: ${backgroundEnter} 0.6s;
`;

const LoginCard = styled.div`
  background: white;
  height: max-content;
  width: 340px;
  border: 1px solid black;
  border-radius: 5px;
  box-shadow: 0 0 40px 4px #131c44;
  transform: translateY(30%) scale(0);
  animation: ${flyIn} 0.5s cubic-bezier(0.3, 0, 0, 0.8) 0.5s;
  animation-fill-mode: forwards;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  line-height: 1.4;
`;

const LoginBody = styled.div`
  max-width: 340px;
`;

const LogoGrid = styled.div`
  display: grid;
  grid-row-gap: 20px;
  align-items: center;
  justify-items: center;
`;

const LogoTitleContainer = styled.div`
  display: grid;
  grid-row-gap: 10px;
  align-items: center;
  justify-items: center;
`;

const StatusBanner = styled.div`
  font-size: 11px;
  font-weight: 600;
  color: white;
  background: ${props =>
    props.error ? '#FF3E00' : 'var(--color-theme-primary)'};
  padding: 10px 20px;
  margin: 0 0 10px;
  text-align: center;
  text-transform: uppercase;
  line-height: 1.8;
`;

const StyledImg = styled.img`
  width: 100%;
  height: 100%;
`;

const StyledLogoIcon = styled(StyledImg)`
  width: ${(props) => props.width || '60px'};
  height: ${(props) => props.height || 'auto'};
`;

const GovInvestTitleText = styled.p`
  font-size: 0.875rem;
  color: var(--color-darkgrey);
  text-align: center;
  margin-top: 5px;
`;

const TrueCompTitleText = styled.p`
  font-size: 0.75rem;
  color: var(--color-grey-d);
  text-align: center;
`;

const TitleText = styled.h1`
  color: var(--color-grey-d);
  font-size: 2em;
  font-weight: 400;
  text-align: center;
  padding: 0 20px;
  margin: 20px 0;
`;

const ContentContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  transition: min-height 0.3s ease-in-out;
`;

const CredentialsContainer = styled.div`
  position: absolute;
  top: 0;
  left: ${props => (props.resetPasswordMode ? '-300px' : '0')};
  width: 100%;
  visibility: visible;
  animation: ${moveOutMoveIn};
  animation-fill-mode: forwards;
  transition: left 0.3s ease-in-out;
  transition-delay: ${props => getMovePositionDelay(props)};
  padding: 5px 0;
`;

const CredentialsInputContainer = styled.div`
  display: grid;
  grid-row-gap: 20px;
  width: 100%;
`;

const LoginButton = styled.div`
  width: auto;
  padding: 15px 20px;
  color: white;
  text-align: center;
  background: var(--color-true-comp-primary);
  cursor: pointer;
  user-select: none;
  border-radius: 5px;
  margin-bottom: 10px;

  &:hover {
    background: var(--color-true-comp-primary);
  }
`;

const LoginFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FooterButton = styled.div`
  color: var(--color-true-comp-primary);
  font-size: ${fontSizes.s};
  font-style: italic;
  user-select: none;
  cursor: pointer;
  &:hover {
    color: var(--color-true-comp-primary);
  }
`;

const PasswordResetContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: ${props => (props.resetPasswordMode ? '0' : '300px')};
  padding: 0 20px 20px 20px;
  visibility: visible;
  animation: ${moveInMoveOut}
  animation-fill-mode: forwards;
  transition: left 0.3s ease-in-out;
  transition-delay: ${props =>
    !props.resetPasswordMode ? '0s' : props.enterpriseMode ? '0.45s' : '0.3s'};
`;
// Component styles END

export default Login;
