import React from 'react';
import '@fortawesome/fontawesome-free-solid';
import './button.css';

function Button(props) {
  const {
      small,
      error,
      color,
      xsmall,
      primary,
      truecompprimary,
      success,
      disabled,
      children,
      secondary,
      danger,
      transparent,
      onClick,
    } = props;

    const styles = `my-button
      ${small ? 'small' : ''}
      ${error ? 'error' : ''}
      ${xsmall ? 'xsmall' : ''}
      ${success ? 'success' : ''}
      ${primary ? 'primary' : ''}
      ${truecompprimary ? 'truecompprimary' : ''}
      ${disabled ? 'disabled' : ''}
      ${secondary ? 'secondary' : ''}
      ${danger ? 'danger' : ''}
      ${transparent ? 'transparent' : ''}`;

  return (
    <div style={{padding:'3px'}}>
      <button className={styles} disabled={disabled} onClick={onClick}>
        <div className="my-button-label" style={{ color: color ? color : '' }}>
          {children}
        </div>
      </button>
    </div>
  );
}

export default Button;