import React from 'react';

function Footer() {
  return (
    <footer id="footer">
      <div style={{width:'100%', textAlign: 'right', paddingRight: '10px'}}>
        Confidential&copy; {new Date().getFullYear()} TrueComp
      </div>
    </footer>
  );
}

export default Footer;